/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import { NEPAL_TZ, TVS_FORMAT_DATE, DEALER_ROLE, CORPORATE_ROLE, MANAGER_ROLE, CASHIER_ROLE } from 'constant';
import moment from 'moment';
import { createContext } from 'react';
import NepaliDate from 'nepali-date';

export const RecordPaymentContext = createContext({
  showOtp: false,
  // eslint-disable-next-line prettier/prettier
  toggleOtp: () => {},
  currency: '',
  loading: false,
  sendOtp: (
    cc: string,
    cp: string,
    at: number | undefined,
    pn: string | undefined,
    ip: boolean | undefined,
    cr: string | undefined,
    pr: string | undefined,
    pa: string | undefined,
    pd: string | undefined,
    // eslint-disable-next-line prettier/prettier
  ) => {},
  totalExpected: 0,
  paid: 0,
});

export const DashboarContext = createContext({
  role: '',
  branch: '',
  loading: false,
});

export const recordPaymentDefaultValue = {
  payment_note: 'Full Payment',
  cash_received: '',
  remarks: '',
  payment_otp: '',
  ptp_date: '',
  total_expected: 0,
  is_ptp: false,
  ptp_amount: '',
  ptp_location: '',
  ptp_collection_address: '',
  is_different_address: false,
  country_code: '',
  cust_phone: '',
  cust_address2: '',
  cust_address2_type: '',
  is_not_provide_phone: false,
  phone_number_relationship: 'Customer',
  otp: '',
  zipcode: '',
  city: '',
  province: '',
  ppd_amount: '',
};

export const AppContext = createContext({
  month: '',
  year: '',
  setYear: (m: string) => {
    console.log(m);
  },
  setMonth: (y: string) => {
    console.log(y);
  },
  timezone: '',
  monthList: [],
  branch: 'All',
  setBranch: (b: string) => {
    console.log(b);
  },
  client: 'All',
  setClient: (c: string) => {
    console.log(c);
  },
  fromToDate: { type: '', f: '', t: '' },
  setFromToDate: (d: { type: string; f: string; t: string }) => {
    console.log(d);
  },
  showListingsSubmenu: false,
  setShowListingsSubmenu: (val: boolean) => {
    console.log(val);
  },
  showReportsSubmenu: false,
  setShowReportsSubmenu: (val: boolean) => {
    console.log(val);
  },
  selectedBranches: [],
  setSelectedBranches(selectedBranches: Array<any>) {
    console.log(selectedBranches);
  },
  ptpFromToDate: { type: '', f: '', t: '' },
  setPtpFromToDate: (d: { type: string; f: string; t: string }) => {
    console.log(d);
  },
  selectedStaffRoles: [],
  setSelectedStaffRoles(selectedStaffRoles: Array<any>) {
    console.log(selectedStaffRoles);
  },
  selectedGeoAccuracies: [],
  setSelectedGeoAccuracies(selectedGeoAccuracies: Array<any>) {
    console.log(selectedGeoAccuracies);
  },
  selectedPaymentNotes: [],
  setSelectedPaymentNotes(selectedPaymentNotes: Array<any>) {
    console.log(selectedPaymentNotes);
  },
  group: 'All',
  setGroup: (g: string) => {
    console.log(g);
  },
  selectedDate: new Date(),
  setSelectedDate: (val: any) => {
    console.log(val);
  },
});

export const getShortName = (name: string) => {
  // eslint-disable-next-line no-return-assign
  const acronym = name.split(/\s/).reduce((response: any, word: any) => (response += word.slice(0, 1)), '');
  return acronym.toUpperCase();
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
};

export const convertTimestamp = (time: string) => {
  const settings = JSON.parse(localStorage.getItem('tvs.settings') || '{}');
  const { timezone: tz } = settings;

  const usTime = moment(time).format(TVS_FORMAT_DATE);
  return convertTvsFormatDate(usTime, tz);
};

export const convertTvsTimestamp = (time: string, tz: string) => {
  if (!time) return '-';
  if (tz === NEPAL_TZ) {
    const nepalTime = new NepaliDate(new Date(time));
    return nepalTime.format('DD/MM/YYYY') + moment(time).format(' hh:mm A');
  }
  return moment(time).format('DD/MM/y hh:mm A');
};

export const convertTvsFormatDate = (time: string, tz: string, format?: string) => {
  if (!time || time === 'Invalid date') return '-';
  if (tz === NEPAL_TZ) {
    const d = moment(time, TVS_FORMAT_DATE).format('Y-MM-DD');
    const t = new NepaliDate(new Date(d));
    return t.format(format || 'DD/MM/YYYY');
  }

  return moment(time, TVS_FORMAT_DATE).format(format || 'DD/MM/YYYY');
};

export const getMonthByTz = (timezone: string, format?: string) => {
  const fm = format || 'MMMM';
  if (timezone === NEPAL_TZ) {
    const d = new NepaliDate();
    return d.format(fm);
  }
  return moment().format(fm);
};

export const getYearByTz = (timezone: string, format?: string) => {
  const fm = format || 'YYYY';
  if (timezone === NEPAL_TZ) {
    const d = new NepaliDate();
    return d.format(fm);
  }
  return moment().format(fm);
};
// Credit Debit Memo changes
export const RecordMemoContext = createContext({
  currency: '',
  loading: false,
  totalExpected: 0,
  paid: 0,
});

export const recordMemoDefaultValue = {
  customer: {
    full_name: '',
  },
  reference_num: '',
  install_num: '',
  total_expected_amount: 0,
  adjustment_amount: 0,
  rec_date: '',
  memo_type: '',
  memo_reason_code: '',
  remarks: '',
  due: {
    penalty_due: 0,
    expected_amt: 0,
  },
  memo_amount: 0,
};

export const isSideBarMenuUsers = (role: string) => {
  return [DEALER_ROLE, CORPORATE_ROLE, MANAGER_ROLE, CASHIER_ROLE].some((e) => e === role);
};

export const flattenGroup = (groups: any[], acc: any[]) => {
  if (groups) {
    groups.forEach((g) => {
      if (g.type === 'A' || g.type === 'G') {
        acc.push({
          code: g.code,
          name: g.name,
          level: g.level,
        });
      }
      if (g.type === 'G') {
        flattenGroup(g.members, acc);
      }
    });
  }
};
