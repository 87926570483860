/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { AppContext } from 'helpers';
import { NEPAL_TZ } from 'constant';
import moment from 'moment';
import NepaliDate from 'nepali-date';

interface Props {
  handleChange: (m: string, y: string) => void;
}

const MonthDropdown = ({ handleChange }: Props) => {
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const { month, year, timezone, monthList } = useContext(AppContext);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [list, setList] = useState<any>([]);
  const [active, setActive] = useState<any>({
    monthIndex: null,
    year: null,
  });

  useEffect(() => {
    if (list.length === 0) renderMonthList();
  }, []);

  useEffect(() => {
    setActive({
      monthIndex: month,
      year,
    });
  }, [month, year]);

  const handleSelectItem = (m: string, y: string) => {
    const finding = monthList.findIndex((item: string) => item === m);

    if (finding > -1) {
      setActive({
        monthIndex: finding,
        year: y,
      });
      handleChange(finding.toString(), y);
    }
  };

  const renderMonthList = () => {
    const currMo = timezone === NEPAL_TZ ? new NepaliDate().format('MM') - 1 : +moment().format('MM') - 1;
    const y = timezone === NEPAL_TZ ? new NepaliDate().format('YYYY') : +moment().format('YYYY');

    const mL = [...Array(12)].map((_, i) => {
      let m = '';
      if (+currMo - i >= 0) {
        m = `${monthList[+currMo - i]} ${y}`;
      } else {
        m = `${monthList[12 + (+currMo - i)]} ${y - 1}`;
      }
      return m;
    });
    setList(mL);
  };

  const renderItem = (item: any) => {
    const st = item.split(' ');
    return (
      <DropdownItemStyle
        key={item}
        onClick={() => {
          handleSelectItem(st[0], st[1]);
        }}
        // className={+active.monthIndex === indx ? 'active' : ''}
      >
        {t(item)}
      </DropdownItemStyle>
    );
  };

  const dropDown = () => {
    return (
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        style={{
          border: '1px solid #6956BA',
          height: '56px',
          borderRadius: '8px',
          background: '#fff',
          width: 'max-content',
        }}
      >
        <DropdownToggleStyle caret color="x" className="p-3 border-0">
          {t(active ? `${monthList[active.monthIndex]} ${active.year}` : '')}
        </DropdownToggleStyle>
        <DropdownMenu className="mt-2">{list && list.map((item: any) => renderItem(item))}</DropdownMenu>
      </Dropdown>
    );
  };

  return dropDown();
};

export const DropdownItemStyle = styled(DropdownItem)`
  &.active {
    background: #ffcc4d;
  }
`;

export const DropdownToggleStyle = styled(DropdownToggle)`
  &.btn {
    color: #263238;
    font-size: 16px;
    font-weight: 400;
    &:hover,
    &:focus,
    &:visited {
      text-decoration: none;
    }
  }
`;

export default MonthDropdown;
