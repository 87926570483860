/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
import React, { FormEvent, ReactElement, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Label, Input, FormGroup, InputGroup, InputGroupAddon, InputGroupText, CustomInput } from 'reactstrap';
import { CustomInputType } from 'reactstrap/es/CustomInput';
import styled, { CSSProperties } from 'styled-components';
import { Localization } from 'constant/config';

type TvsInputProps = {
  hidden?: boolean;
  id: string;
  placeholder?: string;
  noShowError?: boolean;
  label?: string;
  inputIcon?: ReactElement;
  formatType?: 'currency';
  type?: CustomInputType | 'text' | 'textarea' | 'date';
  inline?: boolean;
  labelStyle?: CSSProperties;
  groupStyle?: CSSProperties;
  maxLength?: number;
  inputStyle?: CSSProperties;
  disabled?: boolean;
  handleChange?: (x: any) => void;
  handleBlur?: (x: any) => void;
};

const TvsInput = (props: TvsInputProps) => {
  const {
    hidden = false,
    label,
    id,
    inputIcon,
    noShowError,
    formatType,
    placeholder,
    type,
    inline,
    labelStyle,
    groupStyle,
    maxLength,
    inputStyle,
    disabled,
    handleChange,
    handleBlur,
  } = props;

  const { t } = useTranslation();
  const { errors, touched, values, setValues, isSubmitting } = useFormikContext<any>();

  const [inputTouched, setTouched] = useState(touched[id] || false);
  const inputErrors = (errors && errors[id]) || false;

  const [touch, setTouch] = useState(false);
  const [timer, setTimer] = useState<any>();

  // TODO: verify state change is proper (with sprint 14)
  useEffect(() => {
    if (isSubmitting && !touch) {
      setTouch(true);
    }
  }, [isSubmitting]);

  const currencyFormat = (value: any) => {
    const num = value.replace(/,/gi, '');

    const currencyValue = new Intl.NumberFormat(Localization.currencyLocale).format(num);

    return currencyValue;
    // const splitDecima = num.toString().split('.');
    // const num2 = splitDecima[0].split(/(?=(?:\d{3})+$)/).join(',');
    // const res = splitDecima.length > 1 ? `${num2}.${splitDecima[1]}` : num2;
    // return res;
  };

  const onChange = (e: FormEvent<EventTarget>) => {
    setTouch(true);
    const target = e.target as HTMLInputElement;
    let { value }: any = target;

    if (target.type === 'checkbox') {
      value = target.checked;
    }

    if (formatType === 'currency') {
      if (timer) {
        clearTimeout(timer);
      }
      setTimer(
        setTimeout(() => {
          setValues({
            ...values,
            [id]: currencyFormat(target.value),
          });
        }, 500),
      );
    }

    setValues({
      ...values,
      [id]: value,
    });

    if (handleChange) {
      setTimeout(() => {
        handleChange(value);
      }, 1000);
    }
  };

  const handleOnBlur = (e: any) => {
    setTouch(true);
    if (typeof handleBlur === 'function') handleBlur(e.target.value);
  };

  return (
    <FormGroupStyle
      hidden={hidden}
      className={`${inline ? 'd-flex' : ''} ${disabled ? 'form-group-disabled' : ''}`}
      style={groupStyle || {}}
    >
      {type !== 'checkbox' && label && <Label style={labelStyle}>{label}</Label>}
      <InputGroup style={inputStyle} className={inputIcon ? 'with-icon' : ''}>
        {type && type !== 'text' && type !== 'textarea' && type !== 'date' ? (
          <CustomInput
            autocompleted="off"
            placeholder={placeholder}
            type={type}
            id={id}
            label={type === 'checkbox' ? label : ''}
            onChange={(e: FormEvent<EventTarget>) => onChange(e)}
          />
        ) : (
          <>
            <Input
              placeholder={placeholder}
              id={id}
              type={type as any}
              maxLength={maxLength}
              onChange={(e: FormEvent<EventTarget>) => onChange(e)}
              value={values[id] || ''}
              disabled={disabled}
              autocompleted="off"
              onBlur={handleOnBlur}
            />
          </>
        )}
        {inputIcon && (
          <InputGroupAddon addonType="prepend">
            <InputGroupText>{inputIcon}</InputGroupText>
          </InputGroupAddon>
        )}
      </InputGroup>
      {maxLength && (
        <div className="form-text text-small">
          {maxLength - values[id]?.length} {maxLength - values[id]?.length === 1 ? t('character') : t('characters')}{' '}
          {t('remaining')}.
        </div>
      )}
      {touch && inputErrors && !noShowError && <div className="form-text text-danger text-small">{inputErrors}</div>}
    </FormGroupStyle>
  );
};

const FormGroupStyle = styled(FormGroup)`
  &.form-group-disabled {
    .input-group-text {
      background: #e5e3e3;
      border-left: 0px;
    }
    input {
      background: #e5e3e3;
    }
  }
  .input-group.with-icon {
    input {
      border-right: 0px;
    }
  }
  .input-group-text {
    background: #fff;
    border-left: 0px;
  }
`;
export default TvsInput;
