// @ts-ignore
import { defaults } from './defaultConfigs';

export const Localization = {
  ...defaults,
  assignedCs: 'Assigned CS',
  csId: 'CS ID',
  currencySymbol: '₱',
  currencyAbbrev: 'PHP',
  branch: 'Teams',
  accountBranch: 'Branch',
  branches: 'Branches',
  collectionSpecialist: 'Collection Specialists',
  principalPayment: 'Principal Payment*',
  showPenaltyPayment: true,
  penaltyPayment: 'Penalty Payment*',
  csLeaderBoard: 'CS Leaderboard',
  branchLeaderBoard: 'Branch Leaderboard',
  collectionsBarChartTitle: 'Field Collections',
  accountsBarChartTitle: 'Field Accounts',
  collectionSpecialistPerformanceTitle: 'Collection Specialists Performance',
  branchListingHeader: 'Teams',
  branchListingBreadcrumb: 'Teams',
  csListingHeader: 'Collection Specialists',
  csListingBreadcrumb: 'Collection Specialists',
  csDetailsBreadcrumb: 'Specialist',
  logoFilename: 'TVSDigital_logo.svg',
  clientName: 'TVS Digital',
  navbarBackgroundColor: '#FE0101',
  showManuals: true,
  showAIManuals: true,
  showAPK: false,
  csManualLink: 'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/artha/manuals/Wheeltek_CS_UserGuide.pdf',
  cashierManualLink: 'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/artha/manuals/Wheeltek_Cashier_UserGuide.pdf',
  managerManualLink: 'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/artha/manuals/Wheeltek_Manager_Userguide.pdf',
  aiManualLink: 'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/artha/manuals/Wheeltek_AI_Itinerary.pdf',
  apkLink: process.env.APK_URL || '',
  eulaTextUrl: 'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/eula/EULA_webapp.txt',
  eulaVersionUrl: 'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/eula/EULA_webapp.json',
  privacyStatementUrl:
    'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/privacy-statement/mobile_application_privacy_statement.txt',
  privacyStatementVersion:
    'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/privacy-statement/mobile_application_privacy_statement.json',
  zipcodeLength: 4,
  zipcodeValidationMsg: 'Philippines zipcode should have 4 characters. Ex: 0700, 1234',
  country: 'Philippines',
  countryAbbrev: 'PH',
  transactionListingHeader: 'Transaction Listing',
  transactionListingBreadcrumb: 'Transaction Listing',
  activateTransactionListing: true,
  activateCreditDebitMemo: true,
  activatePPD: true,
  currencyLocale: 'en-US',
  hiddenPenaltyField: false,
  csReportDownload: true,
  cashierReportDownload: true,
  appId: '1:834347563222:web:fbd4fc0f3cdb919c9ab177',
  measurementId: 'G-MF77K6LBTS',
  showTranslation: true,
  recoveredAccountsLabel: 'RECOVERED ACCOUNTS',
  noOfPaymentsLabel: 'NO. OF PAYMENTS',
  showCashierPage: true,
  displayClientCode: false,
  metricsToDisplay: ['mtd-collection-efficiency', 'account-recovery-rate', 'average-visits-per-day'],
  cspStatsToHide: [],
  showSummaryReportDownload: true,
  collectionSpecialistItinerary: 'Collection Specialist Itinerary',
};
